<template>
  <table-view
    class='report-count'
    v-loading='loading'
    :show-page='false'>
    <template #header>
      <view-search-form
        ref='searchFormRef'
        :queryInfo.sync='tableQuery'
        :tool-list="['grade', 'college', 'major']"
        :insert-select-all="[ 'college', 'major']"
        style='display:inline-block;'
        @on-search='renderTable' />
      <div class='header-button fr'>
        <el-button type='success' size='small' @click='exportCountDate'>导出数据</el-button>
      </div>
    </template>
    <!-- body -->
    <el-table
      :data='tableData'
      max-height='700'
      style='width: 100%;'
      border
      stripe
      show-summary
    >
      <el-table-column type='index' label='ID' width='50' fixed>
      </el-table-column>
      <el-table-column prop='collegeName' label='院系' min-width='140px' />
      <el-table-column prop='majorName' label='专业' min-width='200px' />
      <el-table-column prop='allStatusNum' label='录取人数'>
        <el-table-column prop='allStatusNum' label='总数' />
        <el-table-column prop='allStatusManNum' label='男' />
        <el-table-column prop='allStatusWomanNum' label='女' />
      </el-table-column>
      <el-table-column prop='paidNum' label='已缴费人数'>
        <el-table-column prop='paidNum' label='总数' />
        <el-table-column prop='paidManNum' label='男' />
        <el-table-column prop='paidWomanNum' label='女' />
      </el-table-column>
      <el-table-column prop='studyNum' label='已缴费已到校人数'>
        <el-table-column prop='studyNum' label='总数' />
        <el-table-column prop='studyManNum' label='男' />
        <el-table-column prop='studyWomanNum' label='女' />
      </el-table-column>
      <el-table-column prop='notReportedNum' label='已缴费未报到人数'>
        <el-table-column prop='notReportedNum' label='总数' />
        <el-table-column prop='notReportedManNum' label='男' />
        <el-table-column prop='notReportedWomanNum' label='女' />
      </el-table-column>
      <el-table-column label='缴费率'>
        <template v-slot='{row}'>
          <span>{{ orderRate(row) }}%</span>
        </template>
      </el-table-column>
    </el-table>
  </table-view>
</template>

<script>
import { getStudentReportCount } from '@/api/student'
import tableView from '@/vue/mixins/table-view'

export default {
  name: 'reportCount',
  mixins: [tableView],
  data () {
    return {
      tableQuery: {
        grade: new Date().getFullYear(),
        campusId: '',
        collegeId: '',
        majorId: ''
      }
    }
  },
  computed: {
    orderRate () {
      return (data) => {
        const orderTotalYes = Number(data.paidNum) // 已缴费
        const people = Number(data.allStatusNum) // 录取人数
        if (people === 0) {
          return 0
        }
        const rate = (orderTotalYes / people) * 100
        return rate.toFixed(2)
      }
    }
  },
  methods: {
    async renderTable () {
      this.loading = true
      // 不需要 pageSize跟pageNun,自定义方法
      try {
        const res = await getStudentReportCount(this.tableQuery)
        this.tableData = res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    exportCountDate () {
      this.$http.exportExcelHttp.exportCountStuStatus(this.tableQuery, '报到统计数据').then(res => {
      })
    }
  }
}
</script>
